@import "src/styles/designsystem/functions";
@import "src/styles/designsystem/mixins";
@import "src/styles/designsystem/config";
@import "src/styles/ui/mixins";
$selector: ".ShopCartOverviewSummary";

#{$selector} {
  display: flex;
  flex-direction: column;
  gap: 25px;
  min-height: 80px;
  margin: 25px 0;

  &__booking,
  &__amount,
  &__total {
    @include text("meta");
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__total__result {
    color: $text-primary;
    font-weight: bold;
  }

  &__summary {
    display: flex;
    flex-direction: column;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-end;

    button {
      width: 100%;
      margin: 0 !important;
    }
  }
}
